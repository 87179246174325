import store from '@/store'
import { successModal, errorModal, questionModal } from '@/modalMessages'
import { updateProjectDetails } from '@/services/projects'

const schema = [
  {
    type: 'checkbox',
    name: 'is_newly_built',
    label: 'Nieuwbouw'
  }
]

const actions = {
  async load (projectId) {
    try {
      // Project data is already loaded by the layout, no need to call API again.
      const project = store.getters['properties/getProjectById'](projectId)
      const { is_newly_built } = { ...project }
      return { is_newly_built }
    } catch (error) {
      errorModal('Fout bij het laden van projectdetails, probeer het opnieuw.')
      throw error
    }
  },
  async submit (projectId, data) {
    try {
      const project = store.getters['properties/getProjectById'](projectId)
      if (project.has_entities && project.is_newly_built !== data.is_newly_built) {
        const result = await questionModal('Wil je nieuwbouw ook wijzigen voor alle entiteiten die tot dit project behoren?', '')
        data.persist_is_newly_built = result.value
      }
      const response = await updateProjectDetails(projectId, data)
      await store.dispatch('properties/loadProject', projectId)
      successModal('De projectgegevens zijn aangepast')
      return response
    } catch (error) {
      errorModal('Fout bij opslaan van projectinstellingen, probeer het opnieuw.')
      throw error
    }
  }
}

export default {
  heading: 'Kenmerken',
  schema,
  actions
}
